import { lazy, Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Wrapper } from "./components/Wrapper";
import PageHolder from "../tools/placeholder/PageHolder";
import PhFicha from "../pages/ficha/placeholder-loading/PhFicha";
import PhFicha2 from "../pages/ficha/placeholder-loading/PhFicha2";
import Layout from "../Tpl/Layout";
import AuthContext from "../hooks/AuthContext";
import { EcommerceProvider } from "../hooks/EcommerceProvider";
import Home from "../pages/home/Home";

const Tv = lazy(() => import("../pages/tv/Tv"));
const Novos = lazy(() => import("../pages/LandingPage/novos/Novos"));
const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));

const ModalNewsletter = lazy(() => import("../modal/ModalNewsletter"));
const Privacidade = lazy(() => import("../pages/privacidade/Privacidade"));
const Cookies = lazy(() => import("../pages/cookies/Cookies"));
const TermosCondicoes = lazy(() =>
  import("../pages/termosCondicoes/TermosCondicoes")
);
const CarrosViaturas = lazy(() => import("../pages/viaturas/CarrosViaturas"));
const CarroFicha = lazy(() => import("../pages/ficha/CarroFicha"));
const Financiamento = lazy(() =>
  import("../pages/financiamento/Financiamento")
);
const Compramos = lazy(() => import("../pages/compramos/Compramos"));
const Consultadoria = lazy(() =>
  import("../pages/consultadoria/Consultadoria")
);
const Seguros = lazy(() => import("../pages/seguros/Seguros"));
const Lavagens = lazy(() => import("../pages/lavagens/Lavagens"));
const Aluguer = lazy(() => import("../pages/aluguer/Aluguer"));
const Campanhas = lazy(() => import("../pages/campanhas/Campanhas"));
const Oficina = lazy(() => import("../pages/oficina/Oficina"));
const Albuns = lazy(() => import("../pages/album/Albuns"));
const AlbumFicha = lazy(() => import("../pages/album/AlbumFicha"));
const NoticiaFicha = lazy(() => import("../pages/noticias/NoticiaFicha"));
const Noticias = lazy(() => import("../pages/noticias/Noticias"));
const NoticiasGuia = lazy(() => import("../pages/noticias/NoticiasGuia"));

const Erro = lazy(() => import("../pages/erro/Erro"));
const Vendemos = lazy(() => import("../pages/vendemos/Vendemos"));

const Galeria = lazy(() => import("../pages/galeria/Galeria"));
const Testemunhos = lazy(() => import("../pages/testemunhos/Testemunhos"));
const Obrigado = lazy(() => import("../pages/obrigado/Obrigado"));
const Empresa = lazy(() => import("../pages/empresa/Empresa"));
const Contactos = lazy(() => import("../pages/contactos/Contactos"));
const Garantia = lazy(() => import("../pages/garantia/Garantia"));
const TextLegalGarantia = lazy(() =>
  import("../pages/textLegalGarantia/TextLegalGarantia")
);

const renderPaths = (paths, Element) =>
  paths.map((path) => <Route key={path} path={path} element={Element} />);

export default function Rotas({
  setMetaTags,
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  allinfoText,
  selectedCarsComparar,
  removeFromCompareAll,
  setTextoModal,
  textoModal,
  setShowModalComparison,
  showModalComparison,
}) {
  const { state } = useContext(AuthContext);

  return (
    <>
      <Wrapper>
        <Routes>
          <Route
            element={
              <Layout
                infoJson={infoJson}
                allinfoText={allinfoText}
                selectedCarsComparar={selectedCarsComparar}
                removeFromCompareAll={removeFromCompareAll}
                removeFromCompare={removeFromCompare}
                setTextoModal={setTextoModal}
                textoModal={textoModal}
                setShowModalComparison={setShowModalComparison}
                showModalComparison={showModalComparison}
              />
            }
          >
            {infoJson.Layout?.LandingPage?.existe && (
              <>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<PageHolder />}>
                      <LandingPage
                        infoJson={infoJson}
                        setMetaTags={setMetaTags}
                        allinfoText={allinfoText}
                      />
                    </Suspense>
                  }
                />
              </>
            )}

            {infoJson.Layout.Carros.existe ? (
              <>
                {renderPaths(
                  [
                    `${infoJson.Layout?.LandingPage?.existe ? "/home/" : "/"}`,
                    `${
                      infoJson.Layout?.LandingPage?.existe ? "/home/" : "/"
                    }:viatura`,
                    `${
                      infoJson.Layout?.LandingPage?.existe ? "/home/" : "/"
                    }:viatura/:marca`,
                    `${
                      infoJson.Layout?.LandingPage?.existe ? "/home/" : "/"
                    }:viatura/:marca/:modelo`,
                  ],

                  <Home
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                )}
              </>
            ) : (
              <Route
                path={`${
                  infoJson.Layout?.LandingPage?.existe ? "/home/" : "/"
                }`}
                element={
                  <Home
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                }
              />
            )}

            {!infoJson.Layout.Carros.existe && (
              <>
                {renderPaths(
                  [
                    "/viaturas/:viatura",
                    "/viaturas/:viatura/:marca",
                    "/viaturas/:viatura/:marca/:modelo",
                  ],
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <CarrosViaturas
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      selectedItems={selectedItems}
                      addToCompare={addToCompare}
                      removeFromCompare={removeFromCompare}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                )}
              </>
            )}

            {infoJson.Layout?.LandingPage?.novos?.existe && (
              <Route
                path={infoJson.Layout?.LandingPage?.novos?.url}
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Novos infoJson={infoJson} setMetaTags={setMetaTags} />
                  </Suspense>
                }
              />
            )}

            {infoJson.Empresa.existe && (
              <Route
                path={
                  allinfoText.Empresa && allinfoText.Empresa.Url !== ""
                    ? allinfoText.Empresa.Url
                    : infoJson.Empresa.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Empresa
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />{" "}
                  </Suspense>
                }
              />
            )}

            {infoJson.Garantia.existe && (
              <Route
                path={
                  allinfoText.Garantia && allinfoText.Garantia.Url !== ""
                    ? allinfoText.Garantia.Url
                    : infoJson.Garantia.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Garantia
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            {allinfoText.TextLegalGarantia &&
              allinfoText.TextLegalGarantia.Url !== "" && (
                <Route
                  path={
                    allinfoText.TextLegalGarantia &&
                    allinfoText.TextLegalGarantia.Url !== ""
                      ? allinfoText.TextLegalGarantia.Url
                      : infoJson.TextLegalGarantia.url
                  }
                  element={
                    <Suspense fallback={<PageHolder />}>
                      <TextLegalGarantia
                        infoJson={infoJson}
                        setMetaTags={setMetaTags}
                        allinfoText={allinfoText}
                      />
                    </Suspense>
                  }
                />
              )}
            {infoJson.Contactos.existe && (
              <Route
                path={
                  allinfoText.Contactos && allinfoText.Contactos.Url !== ""
                    ? allinfoText.Contactos.Url
                    : infoJson.Contactos.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Contactos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            <Route
              path={
                allinfoText.Termos && allinfoText.Termos.Url !== ""
                  ? allinfoText.Termos.Url
                  : "/termos-condicoes/"
              }
              element={
                <Suspense fallback={<PageHolder />}>
                  <TermosCondicoes
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              }
            />
            <Route
              path={
                allinfoText.Privacidade && allinfoText.Privacidade.Url !== ""
                  ? allinfoText.Privacidade.Url
                  : "/politica-de-privacidade/"
              }
              element={
                <Suspense fallback={<PageHolder />}>
                  <Privacidade
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              }
            />
            <Route
              path={
                allinfoText.Cookie && allinfoText.Cookie.Url !== ""
                  ? allinfoText.Cookie.Url
                  : "/politica-de-cookies/"
              }
              element={
                <Suspense fallback={<PageHolder />}>
                  <Cookies
                    infoJson={infoJson}
                    setMetaTags={setMetaTags}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              }
            />

            {infoJson.Financiamento.existe && (
              <>
                {renderPaths(
                  [
                    `${
                      allinfoText.Financiamento &&
                      allinfoText.Financiamento.Url !== ""
                        ? allinfoText.Financiamento.Url
                        : infoJson.Financiamento.url
                    }`,
                    `${
                      allinfoText.Financiamento &&
                      allinfoText.Financiamento.Url !== ""
                        ? allinfoText.Financiamento.Url
                        : infoJson.Financiamento.url
                    }:codViatura/:marca`,
                  ],
                  <Suspense fallback={<PageHolder />}>
                    <Financiamento
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                )}
              </>
            )}
            {infoJson.Compramos.existe &&
              !infoJson.Compramos.easybuy.existe && (
                <Route
                  path={
                    allinfoText.Compramos && allinfoText.Compramos.Url !== ""
                      ? allinfoText.Compramos.Url
                      : infoJson.Compramos.url
                  }
                  element={
                    <Suspense fallback={<PageHolder />}>
                      <Compramos
                        infoJson={infoJson}
                        setMetaTags={setMetaTags}
                        allinfoText={allinfoText}
                      />
                    </Suspense>
                  }
                />
              )}

            {infoJson.Vendemos?.existe && (
              <Route
                path={
                  allinfoText.Vendemos && allinfoText.Vendemos.Url !== ""
                    ? allinfoText.Vendemos.Url
                    : infoJson.Vendemos?.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Vendemos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}

            {infoJson.Consultadoria.existe && (
              <Route
                path={
                  allinfoText.Consultadoria &&
                  allinfoText.Consultadoria.Url !== ""
                    ? allinfoText.Consultadoria.Url
                    : infoJson.Consultadoria.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Consultadoria
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Seguros.existe && (
              <Route
                path={
                  allinfoText.Seguros && allinfoText.Seguros.Url !== ""
                    ? allinfoText.Seguros.Url
                    : infoJson.Seguros.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Seguros
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Galeria.existe && (
              <Route
                path={
                  allinfoText.Galeria && allinfoText.Galeria.Url !== ""
                    ? allinfoText.Galeria.Url
                    : infoJson.Galeria.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Galeria
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Lavagens.existe && (
              <Route
                path={
                  allinfoText.Lavagens && allinfoText.Lavagens.Url !== ""
                    ? allinfoText.Lavagens.Url
                    : infoJson.Lavagens.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Lavagens
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Aluguer.existe && (
              <Route
                path={
                  allinfoText.Aluguer && allinfoText.Aluguer.Url !== ""
                    ? allinfoText.Aluguer.Url
                    : infoJson.Aluguer.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Aluguer
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Campanhas?.existe && (
              <Route
                path={
                  allinfoText[infoJson.Campanhas?.origem] &&
                  allinfoText[infoJson.Campanhas?.origem].Url !== ""
                    ? allinfoText[infoJson.Campanhas?.origem].Url
                    : infoJson.Campanhas.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Campanhas
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Oficina.existe && (
              <Route
                path={
                  allinfoText.Oficina && allinfoText.Oficina.Url !== ""
                    ? allinfoText.Oficina.Url
                    : infoJson.Oficina.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Oficina
                      setMetaTags={setMetaTags}
                      infoJson={infoJson}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.AlbumClientes.existe && (
              <Route
                path={`${
                  allinfoText.AlbumClientes &&
                  allinfoText.AlbumClientes.Url !== ""
                    ? allinfoText.AlbumClientes.Url
                    : infoJson.AlbumClientes.url
                }:codElemento/*`}
                element={
                  <Suspense fallback={<PageHolder />}>
                    <AlbumFicha
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.AlbumClientes.existe && (
              <Route
                path={
                  allinfoText.AlbumClientes &&
                  allinfoText.AlbumClientes.Url !== ""
                    ? allinfoText.AlbumClientes.Url
                    : infoJson.AlbumClientes.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Albuns
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Testemunhos.existe && (
              <Route
                path={
                  allinfoText.Testemunhos && allinfoText.Testemunhos.Url !== ""
                    ? allinfoText.Testemunhos.Url
                    : infoJson.Testemunhos.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    <Testemunhos
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Noticias.existe && !infoJson.Noticias.externas && (
              <Route
                path={`${
                  allinfoText.Noticias && allinfoText.Noticias.Url !== ""
                    ? allinfoText.Noticias.Url
                    : infoJson.Noticias.url
                }:codElemento/*`}
                element={
                  <Suspense
                    fallback={
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" />
                      </div>
                    }
                  >
                    <NoticiaFicha
                      infoJson={infoJson}
                      setMetaTags={setMetaTags}
                      allinfoText={allinfoText}
                    />
                  </Suspense>
                }
              />
            )}
            {infoJson.Noticias.existe && (
              <Route
                path={
                  allinfoText.Noticias && allinfoText.Noticias.Url !== ""
                    ? allinfoText.Noticias.Url
                    : infoJson.Noticias.url
                }
                element={
                  <Suspense fallback={<PageHolder />}>
                    {infoJson.Noticias.externas ? (
                      <NoticiasGuia
                        infoJson={infoJson}
                        setMetaTags={setMetaTags}
                        allinfoText={allinfoText}
                      />
                    ) : (
                      <Noticias
                        infoJson={infoJson}
                        setMetaTags={setMetaTags}
                        allinfoText={allinfoText}
                      />
                    )}
                  </Suspense>
                }
              />
            )}

            {renderPaths(
              [
                "/obrigado",
                `/obrigado-${(allinfoText.Aluguer &&
                allinfoText.Aluguer.Url !== ""
                  ? allinfoText.Aluguer.Url
                  : infoJson.Aluguer.url
                ).replaceAll("/", "")}/`,
                `/obrigado-${(allinfoText.Compramos &&
                allinfoText.Compramos.Url !== ""
                  ? allinfoText.Compramos.Url
                  : infoJson.Compramos.url
                ).replaceAll("/", "")}/`,
                `/obrigado-${(allinfoText.Consultadoria &&
                allinfoText.Consultadoria.Url !== ""
                  ? allinfoText.Consultadoria.Url
                  : infoJson.Consultadoria.url
                ).replaceAll("/", "")}/`,
                `/obrigado-${(allinfoText.Lavagens &&
                allinfoText.Lavagens.Url !== ""
                  ? allinfoText.Lavagens.Url
                  : infoJson.Lavagens.url
                ).replaceAll("/", "")}/`,
                `/obrigado-${(allinfoText.Financiamento &&
                allinfoText.Financiamento.Url !== ""
                  ? allinfoText.Financiamento.Url
                  : infoJson.Financiamento.url
                ).replaceAll("/", "")}/`,
                `/obrigado-${(allinfoText.Oficina &&
                allinfoText.Oficina.Url !== ""
                  ? allinfoText.Oficina.Url
                  : infoJson.Oficina.url
                ).replaceAll("/", "")}/`,
                `/obrigado-${(allinfoText[infoJson.Campanhas?.origem] &&
                allinfoText[infoJson.Campanhas?.origem].Url !== ""
                  ? allinfoText[infoJson.Campanhas?.origem].Url
                  : infoJson.Campanhas?.url
                )?.replaceAll("/", "")}/`,
              ],

              <Suspense fallback={<PageHolder />}>
                <Obrigado
                  setMetaTags={setMetaTags}
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                />
              </Suspense>
            )}

            {["carros", "motas", "barcos", "pecas", "atrelados", "motores"].map(
              (viatura) => (
                <Route
                  key={viatura}
                  path={`${viatura}/:marca/:modelo/:codViatura`}
                  element={
                    <Suspense
                      fallback={
                        <>
                          {infoJson.Layout.Ficha.tipo === 1 ? (
                            <PhFicha infoJson={infoJson} />
                          ) : (
                            <PhFicha2 infoJson={infoJson} />
                          )}
                        </>
                      }
                    >
                      <EcommerceProvider>
                        <CarroFicha
                          infoJson={infoJson}
                          setMetaTags={setMetaTags}
                          selectedItems={selectedItems}
                          addToCompare={addToCompare}
                          removeFromCompare={removeFromCompare}
                          allinfoText={allinfoText}
                          TipoViatura={viatura}
                        />
                      </EcommerceProvider>
                    </Suspense>
                  }
                />
              )
            )}

            {state.infoPrincipal.length !== 0 &&
              state.infoPrincipal[0].EmailNewsletter && (
                <>
                  <Route
                    path="/newsletter"
                    element={
                      <Suspense
                        fallback={
                          <div className="d-flex align-items-center justify-content-center">
                            <Spinner animation="border" />
                          </div>
                        }
                      >
                        <ModalNewsletter
                          infoJson={infoJson}
                          setMetaTags={setMetaTags}
                          allinfoText={allinfoText}
                          infoPrincipal={state.infoPrincipal}
                        />
                      </Suspense>
                    }
                  />
                </>
              )}

            {renderPaths(
              [`*`, `erro`],
              <Suspense
                fallback={
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" />
                  </div>
                }
              >
                <Erro
                  setMetaTags={setMetaTags}
                  infoJson={infoJson}
                  allinfoText={allinfoText}
                />
              </Suspense>
            )}
          </Route>

          <>
            <Route
              path={"/tv"}
              element={
                <Suspense fallback={<PageHolder />}>
                  <Tv
                    infoJson={infoJson}
                    selectedItems={selectedItems}
                    addToCompare={addToCompare}
                    removeFromCompare={removeFromCompare}
                    allinfoText={allinfoText}
                  />
                </Suspense>
              }
            />
          </>
        </Routes>
      </Wrapper>
    </>
  );
}
