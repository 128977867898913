import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import * as api from "../../../../api/apiCarros";
import useMediaQuery from "../../../hooks/useMediaQuery ";
import SectionZone from "../SectionZone";
import css from "./destaques.module.css";
import { ReactComponent as Mais } from "../../../../assets/icons/mais.svg";
import AuthContext from "../../../hooks/AuthContext";
import { EcommerceProvider } from "../../../hooks/EcommerceProvider";

const BannerDestaque = lazy(() => import("./BannerDestaque"));
const CoverflowSliderBlur = lazy(() => import("./CoverflowSliderBlur"));
const Prism = lazy(() => import("./Prism"));
const SliderNomal = lazy(() => import("./SliderNomal"));
const SoloDestaque = lazy(() => import("./SoloDestaque"));

export default function Destaques({
  infoJson,
  selectedItems,
  addToCompare,
  removeFromCompare,
  tipoDestaque,
  allinfoText,
  autoPlay,
  top,
}) {
  const ismobile = useMediaQuery("(max-width: 574px)");
  const isMobile = useMediaQuery("(min-width: 800px)");
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const [ViaturasDestaques, setViaturasDestaques] = useState([]);
  const { state, dispatch } = useContext(AuthContext);
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
    MetaTitle: "",
    MetaDescription: "",
  });

  useEffect(() => {
    const fichaViatura = async () => {
      const viaturas = await api.getAllDestaques(tipoDestaque, infoJson);

      const motas = state.allMotas.filter(
        (item) =>
          item.Destaque === true &&
          (infoJson.Layout.Destaques.aparecerVendidos
            ? true
            : item.Vendido === false)
      );

      const barcos = state.allBarcos.filter(
        (item) =>
          item.Destaque === true &&
          (infoJson.Layout.Destaques.aparecerVendidos
            ? true
            : item.Vendido === false)
      );

      const vJuntas = viaturas
        .concat(motas)
        .concat(barcos)
        .sort(
          (a, b) => new Date(b.UltimaAlteracao) - new Date(a.UltimaAlteracao)
        );
      dispatch({ type: "AddArrayDestaques", list: vJuntas });
      setViaturasDestaques(vJuntas);
    };
    fichaViatura();
  }, [infoJson, state.allMotas, tipoDestaque, dispatch, state.allBarcos]);

  useEffect(() => {
    allinfoText.Destaques && setAlltexto(allinfoText.Destaques);
  }, [allinfoText]);

  const renderSwitch = (param) => {
    switch (param) {
      case 1:
      case 5:
        return (
          <>
            {ismobile ? (
              <Suspense fallback={<></>}>
                <SliderNomal
                  ViaturasDestaques={ViaturasDestaques}
                  infoJson={infoJson}
                  selectedItems={selectedItems}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
                />
              </Suspense>
            ) : (
              <Suspense fallback={<></>}>
                <CoverflowSliderBlur
                  ViaturasDestaques={ViaturasDestaques}
                  infoJson={infoJson}
                  selectedItems={selectedItems}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
                  autoPlay={autoPlay}
                />
              </Suspense>
            )}
          </>
        );
      case 2:
        return (
          <>
            {!isMobile ? (
              <Suspense fallback={<></>}>
                <SliderNomal
                  ViaturasDestaques={ViaturasDestaques}
                  infoJson={infoJson}
                  selectedItems={selectedItems}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
                />
              </Suspense>
            ) : (
              <Suspense fallback={<></>}>
                <Prism
                  ViaturasDestaques={ViaturasDestaques}
                  infoJson={infoJson}
                  selectedItems={selectedItems}
                  addToCompare={addToCompare}
                  removeFromCompare={removeFromCompare}
                />
              </Suspense>
            )}
          </>
        );
      case 3:
        return (
          <>
            <Suspense fallback={<></>}>
              <BannerDestaque
                ViaturasDestaques={ViaturasDestaques}
                infoJson={infoJson}
                selectedItems={selectedItems}
                addToCompare={addToCompare}
                removeFromCompare={removeFromCompare}
                alltexto={alltexto}
                autoPlay={autoPlay}
              />
            </Suspense>
          </>
        );
      case 4:
        return (
          <>
            <Suspense fallback={<></>}>
              <SoloDestaque
                ViaturasDestaques={ViaturasDestaques}
                infoJson={infoJson}
                selectedItems={selectedItems}
                addToCompare={addToCompare}
                removeFromCompare={removeFromCompare}
                alltexto={alltexto}
                autoPlay={autoPlay}
              />
            </Suspense>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {infoJson.Layout.Destaques.tipo === 3 ? (
        <>
          {ViaturasDestaques.length !== 0 ? (
            <div
              className={`p-0 position-relative ${
                isDesktop
                  ? "container-fluid position"
                  : infoJson.Layout.Destaques.tipo === 3
                  ? "container-fluid position"
                  : "container-md"
              }`}
            >
              <>
                {" "}
                <EcommerceProvider>
                  {renderSwitch(infoJson.Layout.Destaques.tipo)}{" "}
                </EcommerceProvider>
              </>
            </div>
          ) : (
            <div
              className={`p-0 position-relative ${
                isDesktop
                  ? "container-fluid position"
                  : infoJson.Layout.Destaques.tipo === 3
                  ? "container-fluid position"
                  : "container-md"
              }`}
            >
              ola
            </div>
          )}
        </>
      ) : (
        <>
          {ViaturasDestaques.length !== 0 && (
            <SectionZone
              Titulo={
                <>
                  {top && (
                    <>
                      {alltexto.Titulo !== ""
                        ? alltexto.Titulo
                        : "Viaturas em Destaque"}
                    </>
                  )}
                </>
              }
              SubTitulo={
                <>
                  {top && (
                    <>
                      {alltexto.SubTitulo !== ""
                        ? alltexto.SubTitulo
                        : "Sugerimos estas viaturas para si"}
                    </>
                  )}
                </>
              }
              top={!top && 10}
            >
              <div
                className={` position-relative ${
                  infoJson.Layout.Destaques.tipo === 4
                    ? `container-xxxl position ${css.noPaddingXs}`
                    : isDesktop
                    ? "p-0 container-fluid position"
                    : infoJson.Layout.Destaques.tipo === 3
                    ? "p-0 container-fluid position"
                    : "p-0 container-md"
                }`}
              >
                <>
                  <EcommerceProvider>
                    {renderSwitch(infoJson.Layout.Destaques.tipo)}
                  </EcommerceProvider>
                </>

                {!infoJson.Layout.Carros.existe &&
                  !infoJson.Layout.Destaques.naoAparecerBtnToStock && (
                    <Link
                      aria-label={`Ver Listagem Viaturas`}
                      className={css.padding}
                      to={"/viaturas/usadas/"}
                    >
                      <Col
                        xs={12}
                        className={`d-flex justify-content-center mt-3`}
                      >
                        <button className={`btnPesquisa ${css.btnDireccoes}`}>
                          <Mais
                            height="18px"
                            width="18px"
                            stroke="#fff"
                            style={{ marginRight: "5px" }}
                          />
                          Ver Listagem Viaturas
                        </button>
                      </Col>
                    </Link>
                  )}
              </div>
            </SectionZone>
          )}
        </>
      )}
    </>
  );
}
