import { Suspense, lazy } from "react";

import useMediaQuery from "../../components/hooks/useMediaQuery ";
import { Outlet, useLocation } from "react-router-dom";
import { MetaTagsProvider } from "../hooks/metaTagsProvider";

const Dot = lazy(() => import("../../components/tools/dot/Dot"));
const CarComparison = lazy(() =>
  import("../../components/pages/comparador/CarComparison")
);

const TopNav = lazy(() => import("./header/TopNav"));
const Footer = lazy(() => import("./footer/Footer"));

export default function Layout({
  infoJson,
  allinfoText,
  selectedCarsComparar,
  removeFromCompareAll,
  removeFromCompare,
  setTextoModal,
  textoModal,
  setShowModalComparison,
  showModalComparison,
}) {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const location = useLocation();

  const hasLandingPage =
    infoJson.Layout?.LandingPage?.existe &&
    (location.pathname === "/" ||
      (infoJson.Layout?.LandingPage?.novos?.existe &&
        location.pathname === infoJson.Layout?.LandingPage?.novos?.url));

  const hasCampaign =
    infoJson.Campanhas?.landing &&
    location.pathname?.replaceAll("/", "") ===
      `${(allinfoText[infoJson.Campanhas?.origem] &&
      allinfoText[infoJson.Campanhas?.origem].Url !== ""
        ? allinfoText[infoJson.Campanhas?.origem].Url
        : infoJson.Campanhas?.url
      )?.replaceAll("/", "")}`;

  return (
    <MetaTagsProvider>
      {!hasLandingPage && !hasCampaign ? (
        <Suspense fallback={<></>}>
          <TopNav
            path={window.location.pathname}
            infoJson={infoJson}
            allinfoText={allinfoText}
          />
        </Suspense>
      ) : null}

      <Outlet />
      <Suspense fallback={<></>}>
        <Footer infoJson={infoJson} allinfoText={allinfoText} />
      </Suspense>

      {infoJson.Layout.Carros.Comparador && selectedCarsComparar.length > 0 && (
        <Suspense fallback={<></>}>
          <CarComparison
            infoJson={infoJson}
            selectedItems={selectedCarsComparar}
            removeFromCompare={removeFromCompare}
            show={showModalComparison}
            setShow={setShowModalComparison}
            textoModal={textoModal}
            setTextoModal={setTextoModal}
            removeFromCompareAll={removeFromCompareAll}
          />
        </Suspense>
      )}
      {isDesktop && infoJson.Layout.ContactarDot && (
        <Suspense fallback={<></>}>
          <Dot selectedItems={selectedCarsComparar} infoJson={infoJson} />
        </Suspense>
      )}
    </MetaTagsProvider>
  );
}
