import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageRenderer from "../../../tools/images/ImageRenderer";
import { yScrollContext } from "../../../tools/restoreScroll/yScroll";
import css from "../../noticias/noticia.module.css";
import cssAH from "../albumHome/albumHome.module.css";
import Spacer from "../Spacer";

export default function CardRecentes({ data }) {
  const yScroll = useContext(yScrollContext);
  const [imagem, setImagem] = useState("");
  const [srcSet, setSrcSet] = useState("");
  useEffect(() => {
    if (data.Ficheiros.length !== 0) {
      const ImagemPrincipal = data.Ficheiros.filter((carro) => {
        return carro.Principal === true;
      });
      setImagem(ImagemPrincipal[0]);
    }
  }, [data]);

  useEffect(() => {
    if (imagem.Ficheiro) {
      const imagemSeloGrande = imagem.Ficheiro;
      const imagemSeloMedio = imagem.Ficheiro.replace("-6.", "-4.");
      const imagemSeloPequeno = imagem.Ficheiro.replace("-6.", "-2.");

      const imageVariants = [
        {
          srcSet: imagemSeloPequeno,
          media: "(max-width: 320px)",
        },
        {
          srcSet: imagemSeloMedio,
          media: "(max-width:991px)",
        },
        {
          srcSet: imagemSeloGrande,
        },
      ];

      setSrcSet(imageVariants);
    }
  }, [imagem]);

  return (
    <>
      <Col className={`${cssAH.boxPadding}`}>
        <Col className={` position-relative `}>
          <Link
            aria-label={`${data.textMarca} ${
              data.Viatura === "barcos" ? data.textMotorizacao : data.textModelo
            }`}
            to={data.location}
            state={{
              backward: {
                yscroll: yScroll,
              },
            }}
          >
            <Col xs={12}>
              <>
                <ImageRenderer
                  divName={css.imgbEqualNoticia}
                  //   className={`${css.imagem}`}
                  url={srcSet[2]?.srcSet}
                  thumb={srcSet[0]?.srcSet}
                  alt={`${data.textMarca} ${
                    data.Viatura === "barcos"
                      ? data.textMotorizacao
                      : data.textModelo
                  }`}
                  sources={srcSet}
                />
              </>
            </Col>
            <Col
              xs={12}
              className={` ${css.paddingNoticiasClientes} ${css.NoticiasDestaques}`}
            >
              <Spacer clasName="col-12 col nopadding" height="30px" />
              <Col
                xs={12}
                style={{ textAlign: "center" }}
                className={` ${css.noticiasClientesTitulo}  `}
              >
                {data.textMarca}
              </Col>
              <Col
                xs={12}
                style={{ textAlign: "center" }}
                className={` ${css.noticiasClientesData}  `}
              >
                {data.Viatura === "barcos"
                  ? data.textMotorizacao
                  : data.textModelo}
              </Col>
            </Col>
          </Link>
        </Col>
      </Col>
    </>
  );
}
