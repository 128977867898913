import axios from "axios";
import { tratarInfoViatura } from "../components/helpers/tratarInfo";

const global = require("../config/config");

async function getListaBarcos(infoJson) {
  try {
    var API_URL_Lista = {
      method: "GET",
      url: `${global.ApiLink}/v1/easydata/barcos/GetListaBarcos/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_Lista);

    if (res.data.length > 0) {
      const barcos = res.data.map((viatura) => {
        const newViatura = tratarInfoViatura(viatura, "barcos");
        return {
          ...newViatura,
          Viatura: "barcos",
          ValorMensalidade: "",
          Modelo: {
            Id: "",
            Nome: "",
            Tags: null,
          },
        };
      });
      return barcos;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

export { getListaBarcos };
