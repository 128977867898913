import {
  ServicosInfoJson,
  components as componentsServicos,
  imagensDesk as imagensDeskServicos,
  imagensMob as imagensMobServicos,
} from "../../config/configServicos";
import { viaturasConfig } from "../../config/configViaturas";
import { SelectTiposViatura, createLinkPesquisa } from "./tratarInfoCarros";

const ArrayServicosInfoJson = (infoJson, allinfoText) => {
  const createServicos = Object.keys(infoJson)
    .filter((key) => ServicosInfoJson.includes(key))
    .reduce((obj, key) => {
      const DynamicComponent = componentsServicos[key];
      const dynamicImgDesk = imagensDeskServicos[key];
      const dynamicimgMob = imagensMobServicos[key];

      obj[key] = {
        ...infoJson[key],
        titulo:
          allinfoText[key] && allinfoText[key].Titulo
            ? allinfoText[key].Titulo
            : infoJson[key].titulo,
        url:
          allinfoText[key] && allinfoText[key].Url
            ? allinfoText[key].Url
            : infoJson[key].url,
        texto:
          allinfoText[key] && allinfoText[key].Zona
            ? allinfoText[key].Zona
            : "",
        imgDesk: dynamicImgDesk,
        imgMob: dynamicimgMob,
        Svg: <DynamicComponent />,
      };

      return obj;
    }, {});

  const arrServicos = Object.values(createServicos).sort((a, b) => {
    if (a.ordenar && b.ordenar) {
      return a.ordenar - b.ordenar;
    }
    return true;
  });

  return arrServicos;
};

const createObjectViaturas = (infoJson) =>
  Object.keys(viaturasConfig).reduce((obj, key) => {
    obj[key] = {
      ...viaturasConfig[key],
      ...(infoJson.Layout.Carros.tipoViaturas &&
        infoJson.Layout.Carros.tipoViaturas[key]),
    };
    return obj;
  }, {});

const ArrayViaturasInfoJson = (infoJson, state) => {
  //ciar objecto com as viaturas do info json e as por defeito
  const tipoViaturas = createObjectViaturas(infoJson);

  //criar novo objecto que para listar em todas a zonas

  const createViaturas = Object.keys(tipoViaturas).reduce((obj, key) => {
    const DynamicComponent = tipoViaturas[key].svg;
    obj[key] = {
      ...tipoViaturas[key],
      titulo: tipoViaturas[key].titulo,
      Svg: <DynamicComponent />,
      url: createLinkPesquisa(infoJson, key, 1),
      array: state[tipoViaturas[key].state],
      existe: state[tipoViaturas[key].state].length !== 0 ? true : false,
      tipo: createLinkPesquisa(infoJson, key, 2),
      tipoViatura: SelectTiposViatura(key),
      name: key,
    };

    return obj;
  }, {});

  const arrViaturas = Object.values(createViaturas).sort((a, b) => {
    if (a.ordenar && b.ordenar) {
      return a.ordenar - b.ordenar;
    }
    return true;
  });

  return arrViaturas;
};

const selectSegmentos = (infoJson, array, dispatch) => {
  //ciar objecto com as viaturas do info json e as por defeito
  const tipoViaturas = createObjectViaturas(infoJson);
  //criar arrays com as viaturas e envia las para o context

  Object.keys(viaturasConfig).forEach((key) => {
    let arrNew = array;

    Object.keys(viaturasConfig).forEach((key2) => {
      const arr2 =
        tipoViaturas[key2].existe === true
          ? tipoViaturas[key2].filter(
              arrNew,
              key,
              key2,
              tipoViaturas["vendidos"].existe
            )
          : arrNew;

      arrNew = arr2;
    });

    dispatch({
      type: viaturasConfig[key].Dispatch,
      list: tipoViaturas[key].existe === true ? arrNew : [],
    });
  });
};

export { ArrayServicosInfoJson, ArrayViaturasInfoJson, selectSegmentos };
