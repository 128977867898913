import Spacer from "../Spacer";
import css from "./banners.module.css";
import ReactPlayer from "react-player/youtube";

export default function VideoBanner({ Src, existe }) {
  return (
    <>
      {existe && <Spacer height="85px"  />}

      <ReactPlayer
        data-testid="video-youtube"
        className={css.bannerVideo}
        width="100%"
        height="100%"
        url={Src}
        playing={true}
        controls={false}
        loop={true}
        volume={0}
        muted={true}
        config={{
          youtube: {
            playerVars: { rel: 0, showinfo: 0 },
          },
        }}
      />
    </>
  );
}
