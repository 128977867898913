import axios from "axios";

import { tratarInfoViatura } from "../components/helpers/tratarInfo";

const global = require("../config/config");

async function getListaPecas(infoJson) {
  try {
    var API_URL_Lista = {
      method: "GET",
      url: `${global.ApiLink}/v1/easydata/pecas/GetListaPecas/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: { token: process.envx.REACT_APP_TOKEN_API },
    };

    const res = await axios.request(API_URL_Lista);

    if (res.data.length > 0) {
      const pecas = res.data.map((viatura) => {
        const newViatura = tratarInfoViatura(
          viatura,
          viatura.Categoria.Id === 16
            ? "atrelados"
            : viatura.Categoria.Id === 17
            ? "motores"
            : "pecas"
        );
        return {
          ...newViatura,
          Viatura:
            newViatura.Categoria.Id === 16
              ? "atrelados"
              : newViatura.Categoria.Id === 17
              ? "motores"
              : "pecas",
          UltimaAlteracao: "",
          DataCriacao: "",
          ValorMensalidade: "",
          Cor: {
            Id: "",
            Nome: "",
            Tags: null,
          },
          Modelo: {
            Id: "",
            Nome: "",
            Tags: null,
          },
          Transmissao: {
            Id: "",
            Nome: "",
            Tags: null,
          },
          Lugares: {
            Id: "",
            Nome: "",
            Tags: null,
          },
          Estado: {
            Id: 0,
            ...newViatura.Estado,
          },
        };
      });
      return pecas;
    }
    return [];
  } catch (error) {
    console.error("error", error);
  }
}

export { getListaPecas };
